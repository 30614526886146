<template>
  <div>
    <div class="p-3 collapse width">
      <div class="text-center">
        <div
          :class="
            form.is_consent === 1
              ? 'icon-profile-consent mx-auto'
              : 'icon-profile mx-auto'
          "
        >
          {{
            form.first_name_th
              ? form.first_name_th.substring(1, 0).toUpperCase()
              : ""
          }}
        </div>
        <div class="position-check" v-if="form.is_consent === 1">
          <font-awesome-icon icon="check-circle" class="check-circle" />
        </div>
        <!-- <div
          class="profile-img"
          v-bind:style="{
            backgroundImage: 'url(/img/icon-check-green.png)',
          }"
        ></div> -->
      </div>
      <div class="my-2 lh-25">
        <b-row>
          <b-col class="text-center">
            <span class="name mr-2"
              >{{ form.first_name_th }} {{ form.last_name_th }}</span
            >
            <font-awesome-icon
              icon="edit"
              @click.prevent="sidebarEditProfile"
              class="pointer"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <img :src="form.member_level_icon" class="icon-level" />
            {{ form.member_level_name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <font-awesome-icon icon="birthday-cake" class="mr-2 icon-bd" />
            <span v-if="chkBirthday(form.birthday)">{{ form.birthday }}</span>
            <span v-else>
              <span v-if="form.has_birthday_year">{{
                form.birthday | moment($formatDate)
              }}</span>
              <span v-if="!form.has_birthday_year">{{
                form.birthday | moment("DD/MM")
              }}</span>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <font-awesome-icon icon="venus" class="mr-2 icon-bd" />
            <span>{{ form.gender }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <font-awesome-icon icon="credit-card" class="mr-2 icon-bd" />
            <span>{{ form.grand_total | numeral("0,0.00") }}</span> |
            <span>
              <font-awesome-icon
                icon="star"
                size="sm"
                class="mr-1 bg-icon-point"
              />
              <span>{{ form.point_total | numeral("0,0") }} Point</span>
            </span>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col class="text-center">
            <button
              type="button"
              class="bd-social mx-1"
              data-toggle="tooltip"
              data-placement="top"
              :title="form.telephone"
            >
              <font-awesome-icon icon="phone" class="icon-bd" />
            </button>
            <button
              type="button"
              class="bd-social mx-1"
              data-toggle="tooltip"
              data-placement="top"
              :title="form.email"
            >
              <font-awesome-icon icon="envelope" class="icon-bd" />
            </button>
            <!-- <span class="mx-1">
              <img src="/img/icon-line.png" class="img-icon" />
            </span>
            <span class="mx-1">
              <img src="/img/icon-facebook.png" class="img-icon" />
            </span> -->
          </b-col>
        </b-row>
      </div>
      <hr />
      <div>
        <b-row v-if="customerQuestionList.length > 0">
          <b-col class="title-color">
            <font-awesome-icon icon="user" class="icon-bd" />
            ข้อมูลส่วนบุคคล
          </b-col>
          <b-col class="text-right text-underline">
            <b-button @click.prevent="sidebarEditProsonal">Edit</b-button>
          </b-col>
        </b-row>
        <div v-if="!isLoadingPersonal">
          <b-row
            v-for="(item, index) in customerQuestionList"
            :key="index"
            class="d-flex"
          >
            <b-col cols="4"
              ><span class="question">{{ item.question }}:</span>
            </b-col>
            <b-col
              v-for="(anwser, i) in item.answer_list"
              :key="i"
              class="px-0"
              sm="auto"
            >
              <span class="answer"
                >{{ anwser.answer }}
                {{
                  parseInt(i) == parseInt(item.answer_list.length) - 1
                    ? ""
                    : ","
                }}</span
              >
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <OtherLoading />
        </div>
      </div>
      <div class="mt-3">
        <b-row>
          <b-col class="title-color mt-2"
            ><font-awesome-icon icon="home" class="icon-bd" />
            Address Information</b-col
          >
          <b-col class="text-right"
            ><b-button @click.prevent="sidebarEditAddress"
              >Edit</b-button
            ></b-col
          >
        </b-row>
        <b-row>
          <b-col
            class="text-center my-3"
            v-if="
              !form.home_address &&
              !form.alley &&
              !form.road &&
              !form.town &&
              !form.district &&
              !form.subdistrict &&
              !form.province &&
              !form.zip_code
            "
            >No Information Found</b-col
          >
          <b-col v-else
            >{{ form.home_address }} {{ form.alley }} {{ form.road }}
            {{ form.town }} {{ form.district }} {{ form.subdistrict }}
            {{ form.province }} {{ form.zip_code }}</b-col
          >
        </b-row>
      </div>
    </div>
    <SidebarEditProfile
      ref="sidebarProfileEdit"
      :form="form"
      :memberLevelList="memberLevelList"
      :genderList="genderList"
      @updateProfile="updateProfile"
      :v="v"
      @openModalPoint="openModalPoint"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
    />
    <SidebarEditPersonalProfile
      ref="sidebarPersonalEdit"
      :list="customerQuestionList"
      @submitEditPersonal="submitEditPersonal"
      :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
    />
    <SidebarEditAddress
      ref="sidebarAddressEdit"
      :form="form"
      @updateAddress="updateAddress"
    />
  </div>
</template>

<script>
import SidebarEditProfile from "@/components/report/customer/detail/profile/SidebarEditProfile";
import SidebarEditAddress from "@/components/report/customer/detail/profile/SidebarEditAddress";
import SidebarEditPersonalProfile from "@/components/report/customer/detail/profile/SidebarEditPersonalProfile";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    SidebarEditProfile,
    SidebarEditAddress,
    SidebarEditPersonalProfile,
    OtherLoading,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    genderList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    customerQuestionList: {
      required: true,
      type: Array,
    },
    isLoadingPersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
  },
  methods: {
    sidebarEditProfile() {
      this.$refs.sidebarProfileEdit.show();
    },
    sidebarEditAddress() {
      this.$refs.sidebarAddressEdit.show();
    },
    updateAddress(value) {
      this.$emit("updateAddress", value);
    },
    updateProfile(value) {
      this.$emit("updateProfile", value);
    },
    openModalPoint(val) {
      this.$emit("openModalPoint", val);
    },
    sidebarEditProsonal() {
      this.$refs.sidebarPersonalEdit.show();
    },
    submitEditPersonal(val) {
      this.$emit("updatePersonal", val);
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  bottom: 0px;
}
.name {
  font-weight: 600;
}
.bg-icon-point {
  background-color: #ffbe33;
  border-radius: 50px;
  color: #ffe8b8;
}
.icon-bd {
  color: var(--primary-color);
}
.bd-social {
  background-color: var(--secondary-color);
  border-radius: 50px;
  padding: 5px 9px;
  border: none;
}
.img-icon {
  height: auto;
  width: auto;
  max-width: 30px;
  max-height: 30px;
}
.lh-25 {
  line-height: 23px;
}
.collapse.width {
  transition: right 0.3s linear, max-width 0.3s linear;
  max-height: 100% auto;
  max-width: 100%;
  min-width: 50px;
  right: 0;
  position: relative;
  display: block;
}
.icon-profile {
  border-radius: 50px;
  width: 70px;
  height: 70px;
  padding: 10px 0;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  position: relative;
}
.icon-profile-consent {
  padding: 5px 0;
  border: 3px solid #2eb85c;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 15px;
  max-height: 15px;
}
.text-underline {
  text-decoration: underline;
}
.title-color {
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: transparent;
  border: none;
}
.check-circle {
  color: #d7ffe5;
  font-size: 20px;
  background-color: #2eb85c;
  border-radius: 50px;
  border: 2px solid #2eb85c;
}
.position-check {
  top: 15px;
  left: 53%;
  position: absolute;
}
.question {
  color: gray;
}
.answer {
  color: #000;
}
</style>
