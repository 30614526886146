<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="mt-3">
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            placeholder="Search Name, Coupon Code"
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="mt-3">
        <label>Start-End Date</label>
        <div class="date-input">
          <span
            v-if="!filter.start_date"
            @click="$refs.dateStart.isOpen = true"
            class="w-40 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateStart.isOpen = true"
            class="w-50 pointer"
            >{{ filter.start_date | moment($formatDateNew) }}</span
          >
          <span class="px-2">-</span>
          <span
            v-if="!filter.end_date"
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >{{ filter.end_date | moment($formatDateNew) }}</span
          >
          <div :class="'icon-container text-right'">
            <font-awesome-icon
              icon="calendar-alt"
              :class="'cursor-default color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <datetime
          ref="dateStart"
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          v-model="filter.start_date"
        >
        </datetime>
        <datetime
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          ref="dateEnd"
          v-model="filter.end_date"
        >
        </datetime>
      </div>
      <div class="mt-3">
        <b-form-group>
          <label class="label-title">Status</label><br />
          <b-form-checkbox
            v-model="allSelectedStatus"
            @change="toggleAllStatus"
          >
            All
          </b-form-checkbox>
          <b-form-checkbox-group
            id="status"
            v-model="selectedStatus"
            :options="statusFilter"
            value-field="id"
            text-field="name"
            name="status"
            class="ml-4"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  data() {
    return {
      isShowSidebar: false,
      selected: [],
      allSelected: false,
      allSelectedStatus: false,
      selectedStatus: [],
    };
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    statusFilter: {
      required: true,
      type: Array,
    },
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelected = false;
      } else if (newValue.length === this.statusFilter.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    selectedStatus(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelectedStatus = false;
      } else if (newValue.length === this.statusFilter.length) {
        this.allSelectedStatus = true;
      } else {
        this.allSelectedStatus = false;
      }
    },
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.filter.page = 1;
      this.filter.status = this.selectedStatus;
      this.$emit("filterCoupon", this.filter);
      this.hide();
    },
    clearFilter() {
      this.filter.search = null;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.selected = [];
      this.selectedStatus = [];
      this.allSelected = false;
      this.allSelectedStatus = false;
    },
    toggleAllStatus(checked) {
      if (checked) {
        this.selectedStatus = [0, 1, 2, 3];
        this.filter.status = this.selectedStatus;
      } else {
        this.selectedStatus = [];
        this.filter.status = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.w-40 {
  width: 40%;
}
label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
