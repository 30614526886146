<template>
  <b-sidebar
    id="sidebar-profile-edit"
    ref="sidebarProfileEdit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Edit Personal Information</b-col>
      </b-row>
      <div class="py-3">
        <div class="pb-3">
          <b-row>
            <b-col>Prefer Date:</b-col>
            <b-col>{{ form.created_time | moment($formatDateNew) }}</b-col>
          </b-row>
          <b-row>
            <b-col>Spending:</b-col>
            <b-col>{{ form.grand_total | numeral("0,0.00") }} Baht</b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col>Status Consent:</b-col>
            <b-col>
              <b-form-select
                value-field="id"
                text-field="name"
                v-model="form.is_consent"
                :options="isConsentList"
              ></b-form-select>
              <!-- <div v-if="form.is_consent === 1" class="consent-allow">
                ยินยอม
              </div>
              <div v-else class="consent-cancel">รอการยินยอม</div> -->
            </b-col>
          </b-row>
        </div>
        <div>
          <UploadFile
            textFloat="Profile Picture"
            placeholder="Please Select a File To Upload."
            format="image"
            name="thumbnail"
            :fileName="form.picture"
            :accept="acceptType"
            text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
            v-on:onFileChange="onImageChange"
          />
          <div
            class="panel-bg-file-img mr-1 mb-1"
            v-bind:style="{
              backgroundImage: 'url(' + form.picture + ')',
            }"
          >
            <img
              src="@/assets/icons/loading.svg"
              class="loading"
              alt="loading"
              v-if="isLoadingImage"
            />
            <font-awesome-icon
              icon="times-circle"
              class="text-secondary f-right pointer"
              v-if="form.picture"
              @click.prevent="onDeleteImage"
            />
          </div>
        </div>
        <b-row class="mt-3">
          <b-col>
            <InputText
              textFloat="Thai Name"
              placeholder="Thai Name"
              type="text"
              name="first_name_th"
              v-model="form.first_name_th"
              isRequired
              :v="v.form.first_name_th"
              :isValidate="v.form.first_name_th.$error"
            />
          </b-col>
          <b-col>
            <InputText
              textFloat="Thai surname"
              placeholder="Thai surname"
              type="text"
              name="last_name_th"
              v-model="form.last_name_th"
              isRequired
              :v="v.form.last_name_th"
              :isValidate="v.form.last_name_th.$error"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputSelect
              title="Membership Level"
              name="level"
              v-model="form.member_level_id"
              noPleaseSelect
              v-bind:options="memberLevelList"
              valueField="id"
              textField="name"
              @onDataChange="selectMemberLevel"
            />
          </b-col>
          <b-col>
            <label class="title-label">Date/Month/Year</label>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.birthday"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                ref="birthdayDate"
              >
              </datetime>
              <div
                :class="'icon-container text-right'"
                @click="$refs.birthdayDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="6">
            <MultipleRadioButton
              :label="'Gender'"
              :id="'gender'"
              :name="'gender'"
              :options="genderList"
              :textField="'name'"
              :valueField="'id'"
              :value="form.gender"
              @onDataChange="handleChange"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Tel."
              placeholder="Tel."
              type="number"
              name="Tel."
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10);'"
              v-model="form.telephone"
              isRequired
              :v="v.form.telephone"
              :isValidate="v.form.telephone.$error"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Email"
              placeholder="Email"
              type="text"
              name="email"
              v-model="v.form.email.$model"
              :v="v.form.email"
              :isValidate="v.form.email.$error"
            />
          </b-col>
        </b-row>
        <b-row class="point-box mt-2">
          <b-col>
            <div>
              <span class="title-point">Central Cumulative Point:</span>
              <button class="icon-to-point" @click.prevent="openModalPoint">
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
            <div class="title-total-point">
              {{ form.center_point | numeral("0,0") }}
            </div>
          </b-col>
        </b-row>
        <b-row class="point-box mt-2">
          <b-col>
            <div>
              <span class="title-point">Branch Point:</span>
              <button
                class="icon-to-point"
                @click.prevent="openModalBranchPoint"
              >
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
            <div class="title-total-point">
              {{ form.total_branch_point | numeral("0,0") }}
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <InputText
              textFloat="Line ID"
              placeholder="Line ID"
              type="text"
              name="line"
              v-model="form.line_id"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Facebook"
              placeholder="Facebook"
              type="text"
              name="Facebook"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Instagram"
              placeholder="Instagram"
              type="text"
              name="Instagram"
            />
          </b-col>
        </b-row> -->
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
            :disabled="isLoadingUpdateProfile"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
export default {
  components: { UploadFile, InputText, InputSelect, MultipleRadioButton },
  props: {
    form: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    genderList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.picture = "";
    },
    onDeleteImage() {
      this.form.picture = "";
    },
    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },
    submitAction() {
      this.v.form.$touch();

      if (this.v.form.$error) {
        return;
      }
      this.$emit("updateProfile", this.form);
      this.hide();
    },
    handleChange(val) {
      this.form.gender = val;
    },
    openModalPoint() {
      this.$emit("openModalPoint", 1);
    },
    openModalBranchPoint() {
      this.$emit("openModalPoint", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}
.input-container {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
