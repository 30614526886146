<template>
  <div class="bg-white p-3">
    <b-table
      ref="tablecustom"
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(invoice_no)="data">
        <div v-if="tab === 0">
          <div v-if="data.item.invoice_no" class="text-invoice_no">
            <a :href="`/report/transaction/${data.item.transaction_id}`">{{
              data.item.invoice_no
            }}</a>
          </div>
          <div v-else>-</div>
        </div>
        <div class="text-left" v-else>
          <div v-if="data.item.invoice_no" class="text-invoice_no">
            <a :href="`/report/transaction/${data.item.transaction_id}`">{{
              data.item.invoice_no
            }}</a>
          </div>
          <div v-else>-</div>
          <div v-if="data.item.log_type">
            <span
              class="bg-log-type log-type-odd"
              v-if="
                data.item.activity_id == 2 ||
                data.item.activity_id == 3 ||
                data.item.activity_id == 4 ||
                data.item.activity_id == 8 ||
                data.item.activity_id == 10 ||
                data.item.activity_id == 22 ||
                data.item.activity_id == 23 ||
                data.item.activity_id == 26 ||
                data.item.activity_id == 30
              "
              >{{ data.item.log_type }}</span
            >
            <span v-else class="bg-log-type log-type-even">{{
              data.item.log_type
            }}</span>
            <!-- <span
              :class="
                data.index % 2 == 0
                  ? 'bg-log-type log-type-even'
                  : 'bg-log-type log-type-odd'
              "
              >{{ data.item.log_type }}</span
            > -->
          </div>
          <div>Save : {{ data.item.note }}</div>
        </div>
      </template>
      <template v-slot:cell(transaction_date)="data">
        <div v-if="tab === 0">
          {{ data.item.transaction_date | moment($formatDateNew) }}
        </div>
        <div v-else>
          <div>{{ data.item.transaction_date | moment("DD/MM/YYYY") }}</div>
          <div class="datetime">
            {{ data.item.transaction_date | moment("HH:mm:ss") }}
          </div>
        </div>
      </template>
      <template v-slot:cell(status_name)="data">
        <div v-if="parseInt(data.item.status) == 1" class="status-complete">
          {{ data.item.status_name }}
        </div>
        <div v-if="parseInt(data.item.status) == 2" class="status-void">
          {{ data.item.status_name }}
        </div>
        <div v-if="parseInt(data.item.status) == 3" class="status-cancel">
          {{ data.item.status_name }}
        </div>
      </template>
      <template v-slot:cell(sale_name)="data">
        <div v-if="data.item.sale_name">{{ data.item.sale_name }}</div>
        <div v-else>-</div>
      </template>
      <template v-slot:cell(current_point)="data">
        <div>{{ data.item.current_point | numeral("0,0") }}</div>
      </template>
      <template v-slot:cell(branch_name)="data">
        <div>{{ data.item.branch_name ? data.item.branch_name : "-" }}</div>
      </template>
      <template v-slot:cell(point)="data">
        <div v-if="Math.sign(data.item.point) == -1" class="status-cancel">
          {{ data.item.point | numeral("0,0") }}
        </div>
        <div
          v-else-if="parseInt(data.item.point) === 0"
          class="status-complete"
        >
          {{ data.item.point }}
        </div>
        <div v-else class="status-complete">
          +{{ data.item.point | numeral("0,0") }}
        </div>
      </template>
      <template v-slot:cell(grand_total)="data">
        <div>{{ data.item.grand_total | numeral("0,0.00") }}</div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    tab: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
    };
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("handleChangeTake", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a {
  color: #333 !important;
}
.text-invoice_no {
  color: #333;
  text-decoration: underline;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: red;
}
.bg-log-type {
  opacity: 0.5;
  border-radius: 50px;
  padding: 0 10px;
}
.log-type-odd {
  background-color: #d79b9b;
  color: red;
}
.log-type-even {
  background-color: #9bd79b;
  color: green;
}
.datetime {
  color: #919191;
}
</style>
