<template>
  <b-sidebar
    id="sidebar-personal-edit"
    ref="sidebarPersonalEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter"> More Information</b-col>
      </b-row>
      <div v-for="(item, index) in list" :key="index">
        <b-row v-if="item.field_type_id === 1">
          <b-col v-if="item.answer_list.length > 0">
            <div v-for="(answer, i) in item.answer_list" :key="i">
              <CustomField
                :value="answer.answer"
                :placeholder="item.question"
                :label="item.question"
                @handleChangeValue="handleChangeValue"
                :fieldtype="item.field_type_id"
                :questionId="item.question_id"
              />
            </div>
          </b-col>
          <b-col v-else>
            <CustomField
              :placeholder="item.question"
              :label="item.question"
              @handleChangeValue="handleChangeValue"
              :fieldtype="item.field_type_id"
              :questionId="item.question_id"
            />
          </b-col>
        </b-row>
        <b-row v-if="item.field_type_id === 2">
          <b-col>
            <CustomCheckbox
              :options="item.choice_list"
              :label="item.question"
              :fieldtype="item.field_type_id"
              :questionId="item.question_id"
              @handleChangeCheckbox="handleChangeCheckbox"
            />
          </b-col>
        </b-row>
        <b-row v-if="item.field_type_id === 4">
          <b-col>
            <CustomDropdown
              :options="item.choice_list"
              :label="item.question"
              :fieldtype="item.field_type_id"
              :questionId="item.question_id"
              @handleChangeDropdown="handleChangeDropdown"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            :disabled="isLoadingUpdatePersonal"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import CustomField from "@/components/report/customer/detail/fields/CustomField";
import CustomCheckbox from "@/components/report/customer/detail/fields/CustomCheckbox";
import CustomDropdown from "@/components/report/customer/detail/fields/CustomDropdown";
export default {
  components: {
    CustomField,
    CustomCheckbox,
    CustomDropdown,
  },
  props: {
    list: {
      required: true,
      type: Array,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      questionList: [],
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    handleChangeValue(data) {
      let body = {
        question_id: data.questionId,
        field_type_id: data.type,
        answer_list: [
          {
            answer_id: 0,
            answer: data.answer,
          },
        ],
      };
      this.questionList.push(body);
    },
    handleChangeDropdown(data) {
      let body = {
        question_id: data.questionId,
        field_type_id: data.type,
        answer_list: [
          {
            answer_id: data.answer_id,
            answer: data.answer,
          },
        ],
      };
      this.questionList.push(body);
    },
    handleChangeCheckbox(data) {
      const findId = this.questionList.find(
        (el) => el.question_id === data.questionId
      );
      if (findId) {
        for (const list of this.questionList) {
          if (data.questionId === list.question_id) {
            list.answer_list = data.answer;
          }
        }
      } else {
        let body = {
          question_id: data.questionId,
          field_type_id: data.type,
          answer_list: data.answer,
        };
        this.questionList.push(body);
      }
    },
    submitAction() {
      for (const field of this.list) {
        if (
          !this.questionList
            .map(function (e) {
              return e.field_type_id;
            })
            .includes(field.field_type_id)
        ) {
          if (field.field_type_id !== 1) {
            for (const choice of field.choice_list) {
              if (choice.is_default && field.answer_list.length === 0) {
                this.questionList.push({
                  question_id: field.question_id,
                  field_type_id: field.field_type_id,
                  answer_list: [
                    {
                      answer_id: choice.choice_id,
                      answer: choice.choice,
                    },
                  ],
                });
              }
            }
          }
        }
      }
      this.$emit("submitEditPersonal", this.questionList);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  font-weight: 100;
}
</style>
